<template>
  <NotulenForm mode="Ubah" module="Notulen Rakom, Rawas, RUPS"> </NotulenForm>
</template>

<script>
import NotulenForm from './form';

const NotulenUpdate = {
  name: 'NotulenUpdate',
  components: { NotulenForm },
};

export default NotulenUpdate;
</script>
